<template>
  <div v-if="DataBase != null">
      <div v-if="DataBase.settings.premium.type != 1 && DataBase.settings.premium.type != 2">
        <span v-if="googleAdds == true">
            <InFeedAdsense
                data-ad-layout-key="-fi+6j-17-cv+rc"
                data-ad-client="ca-pub-7031617318723191"
                data-ad-slot="5522676145">
            </InFeedAdsense>
        </span>
        <span v-if="googleAdds == false">
            <Adsense
                data-ad-client="ca-pub-7031617318723191"
                data-ad-slot="8038649538">
            </Adsense>
        </span>
      </div>
  </div>
</template>

<script>
export default {
    props: {
        googleAdds: { type: Boolean, default: true },
        intervalAdds: { type: Number , default: 30000},
        changeAdds: { type: Boolean, default: false},
    },
    computed:{
        DataBase:{
            get: function() {return this.$store.state.portfolio.DataBase },
            set: function(newValue) {this.$store.state.portfolio.DataBase = newValue} 
        },
    },
    mounted(){
        setInterval(() => {
            if(this.changeAdds) this.googleAdds = (this.googleAdds ? false : true)
        }, this.intervalAdds);
    },

}
</script>

<style>

</style>