export default {
    namespaced: true,
    state: {
        DataBase: null,
        PortfolioCalc: [],
        CallOrderByIni: new Date(),
        calculating:false,
        progress:0,
        ValueBuy:1000
    },
    getters: {
        CurrentPercentage: (state) => (code) => {
            let SumPrices = 0
            const stocks = state.DataBase.portfolio.stocks.filter(stocks => stocks.code == code)[0]
            state.DataBase.portfolio.stocks.map( stocks => {
                SumPrices += (stocks.priceBRL * parseFloat(stocks.quantity))
            })
            // SumPrices -= (stocks.priceBRL * parseFloat(stocks.quantity))
            return (stocks.priceBRL * parseFloat(stocks.quantity)) * 100 / SumPrices
        },
        PercentageByClass: (state) => (code) => {
            let SumNote = 0
            const stocks = state.DataBase.portfolio.stocks.filter(stocks => stocks.code == code)[0]
            const NewDataBase = state.DataBase.portfolio.stocks.filter(portfolio => portfolio.class == stocks.class)

            NewDataBase.map( stocks => {
                SumNote += parseFloat(stocks.note)
            })
            //SumNote -= parseFloat(stocks.note)
            return (stocks.note * 100 / SumNote)
        },
        Percentage: (state) => (code, Percentage) => {
            const PercentageClass = state.DataBase.portfolio.class.filter(item => item.code == code)[0].objectivePercentage
            return (Percentage * PercentageClass / 100)
        },
        ValueByClass: (state) => (className, HasBRL = true) => {
            let SumValue = 0
            const stocks = state.DataBase.portfolio.stocks.filter(stocks => stocks.class == className)
            stocks.map( stocks => {
                let Price = HasBRL  ? stocks.priceBRL: stocks.priceUSD
                SumValue += (Price * parseFloat(stocks.quantity))
            })
            return SumValue;
        },
        GetObjectivePercentage: (state) => (className) => {
            try {
                return state.DataBase.portfolio.class.filter(_class => _class.code == className)[0].objectivePercentage
            } catch (error) {
                return  0;
            }
            
        },
        GetCurrentPercentage: (state) => (className) => {
            let SumPortfolio = 0
            let SumClass = 0
            const stocks = state.DataBase.portfolio.stocks.filter(stocks => stocks.class == className)
            state.DataBase.portfolio.stocks.map( stocks => {
                SumPortfolio += (stocks.priceBRL * parseFloat(stocks.quantity))
            })
            stocks.map( stocks => {
                SumClass += (stocks.priceBRL * parseFloat(stocks.quantity))
            })
            return  SumClass * 100 / SumPortfolio
        },
        GetDecimalPlaces: (state) => (Class) => {
            switch (Class) {
                case 'rf':
                    return 2
                case 'criptomoedas':
                    return 8
                case 'stocks':
                case 'reits':
                case 'etf_eua':
                case 'adrs':
                    if(state.DataBase.settings.usesDecimal)
                        return 4
                    else
                        return 0
                default:
                    return 0
            }
        }
    },
    mutations: {
        setPortfolioCalc(state, payload) {
            state.PortfolioCalc = payload
        },
    },
    actions: {
        
    },
    modules: {
    }
}