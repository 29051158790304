export default {
    namespaced:true,
    state: {
        CurrentUser:{},
        photo:null
    },
    getters: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
    }
}