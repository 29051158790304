<template>
    <div>
        
        <div class="container" v-if="$route.name != 'Pricing'">
            <!-- <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Apoiadores</h3>
                </div>
                <div class="card-body">
                    <div > 
                        <div v-if="!$isMobile()" class="text-center">
                            <img src="https://carteiraholder.com.br//public/BannerDesktop.png" alt="">
                        </div>
                        <div v-if="$isMobile()" class="text-center m-3">
                            <img src="https://carteiraholder.com.br//public/BannerMobile.png" alt="">
                        </div>
                    </div>
                </div>
            </div> -->
            <div v-if="DataBase != null">
                <div v-if="DataBase.settings.premium.type != 1 && DataBase.settings.premium.type != 2" class="row">
                    <div class="col-12">
                        <Books />
                    </div>

                    <div class="col-12 col-md-6">
                        <div class="card">
                            <div class="card-body">
                                <GoogleAdds changeAdds="true" />
                            </div>
                        </div>
                    </div>
                    <div v-if="!$isMobile()" class="col-12 col-md-6">
                        <div class="card">
                            <div class="card-body">
                                <GoogleAdds changeAdds="true"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <footer class="footer">
            <div class="container">
                <b-row>
                    <b-col>
                        <div class="rivacy" v-if="!$isMobile()">
                            <a href="mailto:contato@carteiraholder.com.br" class="btn-footer btn btn-link">Contato</a>
                            <!-- <a href="#" class="btn-footer btn btn-link">Sobre</a> -->
                            <router-link to="/tutorial" class="btn-footer btn btn-link">Tutorial</router-link>
                        </div>
                        <div class="social">				
                            <ul class="text-center m-0 ">
                                <li>
                                    <a class="social-icon" href="https://www.facebook.com/Carteira-Holder-109246630884843/" target="_blank">
                                        <font-awesome-icon :icon="['fab', 'facebook']" />
                                    </a>
                                </li>
                                <li>
                                    <a class="social-icon" href="https://www.instagram.com/carteiraholder/" target="_blank">
                                        <font-awesome-icon :icon="['fab', 'instagram']" />
                                    </a>
                                </li>
                                <li>
                                    <a class="social-icon" href="https://www.youtube.com/channel/UCAB75e3OQJw3O0QdWwbeqXw" target="_blank">
                                        <font-awesome-icon :icon="['fab', 'youtube']" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                        
                    </b-col>
                    <b-col>
                        <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center text-muted">
                            <h6>Apoiador</h6> 
                            <a href="https://insideapp.com.br/" target="_blank">
                                <img src="@/assets/logos/Inside-Logo-azul.png" width="120" alt="" class="img-fluid">
                            </a>
                        </span>
                    </b-col>
                </b-row>
                
                <div class="row align-items-center flex-row-reverse">
                    <div class="col-md-12 col-sm-12 mt-3 mt-lg-0 text-center">
                        <a href="https://carteiraholder.com.br/docs/Condicoes-e-Termos-de-Uso-Carteira-Holder.pdf" class="btn-footer btn btn-link" target="_blank">Termos de Uso</a> 
                        <span v-if="!$isMobile()">-</span>
                        <a href="https://carteiraholder.com.br/docs/politica-de-protecao-aos-direitos-autorais.pdf" class="btn-footer btn btn-link" target="_blank">Proteção aos Direitos Autorais</a>
                        <span v-if="!$isMobile()">-</span>
                        <a href="https://carteiraholder.com.br/docs/Politica-de-Privacidade-LGPD.pdf" class="btn-footer btn btn-link" target="_blank">LGPD</a>
                    </div>
                    <div class="col-md-12 col-sm-12 mt-3 mt-lg-0 text-center">
                        Copyright © 2020 - {{new Date().getFullYear()}} | Carteira Holder. Todos os direitos reservados.
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
import GoogleAdds from '@/components/widgets/GoogleAdds'
import Books from '@/components/widgets/Books'
export default {
    components:{ GoogleAdds, Books},
    computed:{
        DataBase:{
            get: function() {return this.$store.state.portfolio.DataBase },
            set: function(newValue) {this.$store.state.portfolio.DataBase = newValue} 
        },
    },
}
</script>

<style>
    .social{
        margin-left: 6px !important;
        margin-top: -10px !important;
    }
</style>