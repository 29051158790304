import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { 
    faUserSecret, faUser, faWrench, faLifeRing, faSignOutAlt, faHome, faWallet, faBalanceScaleRight, faChalkboardTeacher,
    faBars, faEye, faEyeSlash, faCircleNotch, faSpinner, faIndustry, faBuilding, faUniversity, faChartLine, faFileInvoiceDollar, 
    faGlobeAmericas, faCity, faDollarSign, faAtlas, faStar, faCaretRight, faCaretDown, faCaretUp, faArrowsAltV, faArrowDown, faArrowUp,
    faLongArrowAltDown, faLongArrowAltUp, faChevronUp, faChevronDown, faTimes, faPencilAlt, faTrashAlt, faShoppingCart, faEdit, faCartPlus, 
    faSortAmountDown, faSortAmountUp, faSortAmountUpAlt, faPlusSquare, faPlus, faFire, faExchangeAlt, faMinus, faBell, faTrophy, faExclamationCircle, 
    faCheck, faThLarge, faChevronRight, faEnvelopeOpenText, faEnvelope, faGraduationCap, faHistory
} from '@fortawesome/free-solid-svg-icons'
import { faGoogle, faFacebookSquare, faInstagramSquare, faYoutubeSquare, faBitcoin, faFacebook, faInstagram, faYoutube, faFreeCodeCamp  } from '@fortawesome/free-brands-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
    faGoogle, faFacebookSquare, 
    faUserSecret, faUser, faWrench, faLifeRing, faSignOutAlt, faHome, faWallet, faBalanceScaleRight, faChalkboardTeacher,
    faBars, faEye, faEyeSlash, faInstagramSquare, faYoutubeSquare, faCircleNotch, faSpinner, faIndustry, faBuilding, faUniversity, faChartLine,
    faFileInvoiceDollar, faGlobeAmericas, faCity, faDollarSign, faAtlas, faBitcoin, faFacebook, faInstagram,faYoutube, faStar, faCaretRight,
    faCaretDown, faCaretUp, faArrowsAltV, faArrowDown, faArrowUp, faLongArrowAltDown, faLongArrowAltUp, faChevronUp, faChevronDown, faTimes,
    faPencilAlt, faTrashAlt, faShoppingCart, faEdit, faCartPlus, faSortAmountDown, faSortAmountUp, faSortAmountUpAlt, faPlusSquare, faPlus,
    faFire, faExchangeAlt, faFreeCodeCamp, faGlobeAmericas, faMinus, faBell, faTrophy, faExclamationCircle, faCheck, faEye, faEyeSlash,
    faThLarge, faChevronRight,faEnvelopeOpenText, faEnvelope,faGraduationCap, faHistory
)
Vue.component('font-awesome-icon', FontAwesomeIcon)
