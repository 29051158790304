<template>
  <div class="container">
    <ul class="nav">
      <li
        class="nav-item with-sub"
        v-for="nav in navs"
        :key="nav.id"
        v-show="nav.visible"
        :class="'nav-item ' + (nav.children.length > 0 ? 'with-sub' : '')"
      >
        <div v-show="nav.visible">
          <a v-if="nav.children.length > 0" class="nav-link" to="#">
            <font-awesome-icon :icon="nav.icon" />
            <span class="text-white">-</span> {{ nav.name }}
          </a>
          <router-link
            v-else
            class="nav-link"
            active-class="active"
            :to="nav.path"
          >
            <font-awesome-icon :icon="nav.icon" />
            <span class="text-white">-</span> {{ nav.name }}
          </router-link>
        </div>
        <div v-show="nav.children.length > 0" class="sub-item">
          <ul>
            <li
              v-for="children in nav.children"
              :key="children.id"
              v-show="children.visible"
            >
              <router-link :to="children.path">{{ children.name }}</router-link>
            </li>
          </ul>
        </div>
      </li>
      <li class="nav-item with-sub" @click="SingOut">
        <a class="nav-link" to="#">
          <font-awesome-icon icon="sign-out-alt" />
          <span class="text-white">-</span> SAIR
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import firebase from "firebase/app";
export default {
  data() {
    return {
      navs: [
        {
          children: "",
          icon: "th-large",
          id: 1,
          name: "Dashboard",
          path: "/home",
          visible: true,
        },
        {
          children: [
            {
              id: 2.1,
              name: "Configurações",
              path: "/portfolio/settings",
              visible: false,
            },
            {
              id: 2.2,
              name: "Rentabilidade",
              path: "/portfolio/return",
              visible: false,
            },
            {
              id: 2.3,
              name: "Patrimônio",
              path: "/portfolio/equity",
              visible: false,
            },
            {
              id: 2.4,
              name: "Composição",
              path: "/portfolio/composition",
              visible: false,
            },
            {
              id: 2.5,
              name: "Transações",
              path: "/portfolio/transactions",
              visible: false,
            },
            {
              id: 2.6,
              name: "Proventos",
              path: "/portfolio/dividends",
              visible: false,
            },
            {
              id: 2.7,
              name: "Setor",
              path: "/sector",
              visible: true,
            },
            {
              id: 2.8,
              name: "Alocação",
              path: "/allocation",
              visible: true,
            },
          ],
          icon: "wallet",
          id: 2,
          name: "Carteira",
          path: "",
          visible: true,
        },
        {
          children: [
            {
              id: 3.1,
              name: "Rebalancear",
              path: "/rebalancing",
              visible: true,
            },
            {
              id: 3.2,
              name: "Carteira Meta",
              path: "/target",
              visible: true,
            },
          ],
          icon: "balance-scale-right",
          id: 3,
          name: "Rebalanceamento",
          path: "",
          visible: true,
        },
        {
          children: "",
          icon: "graduation-cap",
          id: 4,
          name: "Tutorial",
          path: "/tutorial",
          visible: true,
        },
        {
          children: "",
          icon: "trophy",
          id: 5,
          name: "Seja Premium",
          path: "/pricing",
          visible: true,
        },

        {
          children: [
            {
              id: 6.1,
              name: "Perfil",
              path: "/profile",
              visible: true,
            },
            {
              id: 6.2,
              name: "Configurações",
              path: "/settings",
              visible: true,
            },
            {
              id: 6.3,
              name: "Notificações",
              path: "/notification",
              visible: true,
            },
            {
              id: 6.4,
              name: "Histórico de Pagamento",
              path: "/history",
              visible: true,
            },
          ],
          icon: "user",
          id: 6,
          name: "Usuário",
          path: "",
          visible: true,
        },
      ],
    };
  },
  methods: {
    SingOut() {
      this.$store.state.global.firestore();
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$store.state.portfolio.DataBase = null;
          this.$router.replace("/");
        });
    },
  },
};
</script>

<style></style>
