<template>
    <div></div>
</template>

<script>
import firebase from "firebase/app"
import md5 from 'js-md5'
export default {
    computed:{
        currentUser(){
            return this.$store.state.user.CurrentUser
        },
        DataBase:{
            get: function() {return this.$store.state.portfolio.DataBase },
            set: function(newValue) {this.$store.state.portfolio.DataBase = newValue} 
        },
    },
    mounted(){
        setInterval(() => {
            if(this.DataBase == null) return
            if(this.DataBase.settings.premium.lastGet === undefined) this.DataBase.settings.premium.lastGet = 0
            if(this.DataBase.email === undefined) this.DataBase.email = this.$store.state.user.CurrentUser.email

            let params = new URLSearchParams()
            params.append('email', this.currentUser.email )
            params.append('uid',this.currentUser.uid)
            this.$api.post("/Premium/SetPremium/" + this.DataBase.settings.premium.lastGet ,params)
            this.$api.post("/Premium/GetPremium/" + this.DataBase.settings.premium.lastGet ,params)
                .then( reponse => {
                    if(reponse.data.error == true) return
                    this.checkResponse(reponse.data.value)
                })
        }, 1000 * 60 * 1);
    },
    methods:{
        checkResponse(json){
            for (let index = 0; index < json.length; index++) {
                const element = json[index];
                const Key = md5(this.$store.state.global.AccessToken + element.uid + "|||" + element.email + "|||" + element.months + "|||" + element.type)
                if(element.key != Key) continue
                if(this.DataBase.settings.premium.lastGet > element.id) continue

                this.DataBase.notification.push({
                    id:1000,
                    title:"Parabéns",
                    text:"Agora você tem o ACESSO " + (element.type == 1 ? "AVANÇADO" : "PROFISSIONAL"),
                    view:false, 
                    delete:false,
                    date: (new Date()).toLocaleDateString()
                })

                this.DataBase.settings.premium.lastGet = element.id
                if(this.DataBase.settings.premium.type == 0) this.DataBase.settings.premium.expirationDate = new Date()
                else this.DataBase.settings.premium.expirationDate = new Date(this.DataBase.settings.premium.expirationDate)
                this.DataBase.settings.premium.type = element.type
                this.DataBase.settings.premium.expirationDate = new Date(this.DataBase.settings.premium.expirationDate.setMonth(this.DataBase.settings.premium.expirationDate.getMonth()+element.months));
                firebase.analytics().logEvent('Buy ' + element.months + ' months');
            }
            let dd = String(this.DataBase.settings.premium.expirationDate.getDate()).padStart(2, '0');
            let mm = String(this.DataBase.settings.premium.expirationDate.getMonth() + 1).padStart(2, '0'); //January is 0!
            let yyyy = this.DataBase.settings.premium.expirationDate.getFullYear();
            this.DataBase.settings.premium.expirationDate = yyyy + '-' + mm + '-' + dd;

            firebase.firestore().collection('account')
                .doc(this.$store.state.user.CurrentUser.uid)
                .set(this.DataBase)
                .then(() => {
                    let params = new URLSearchParams()
                    params.append('email', this.currentUser.email )
                    params.append('uid',this.currentUser.uid)
                    this.$api.post("/Premium/SetPremium/" + this.DataBase.settings.premium.lastGet ,params)
                })
                .catch((error) => {
                    let params = new URLSearchParams()
                    params.append('email', this.$store.state.user.CurrentUser.email )
                    params.append('event',  JSON.stringify(error) )
                    this.$api.post("/Errorlog/Set", params)
                    console.log(error);
                })

        },
    }
}
</script>

<style>

</style>