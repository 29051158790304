import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import firebase from "firebase/app"
import {Settings} from "./settings/firebase";

import 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'
import 'firebase/storage'

import "./plugins/fusioncharts"
import "./plugins/composition"
import "./plugins/maks"
import "./plugins/boostrap"
import "./plugins/fontawesome"
import "./plugins/axios"
import "./plugins/filter"
import "./plugins/swal"
// import "./plugins/apexcharts"
import "./plugins/MobileDetection"
import "./plugins/img-resize"
import "./plugins/google-adsense"
// import "./plugins/facebook-pixel"

Vue.config.productionTip = false

// Initialize Firebase
firebase.initializeApp(Settings)
firebase.auth().languageCode = 'pt-BR'

let app = ''
firebase.auth().onAuthStateChanged(() => {
  if(!app){
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')

    // if(!app.$isMobile()){
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
    // }else{
      // firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    // }
  }
})

// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')