import Vue from 'vue'
import axios from 'axios'
// import firebase from "firebase/app"
// import md5 from 'js-md5'

Vue.use({
    install(Vue) {
        Vue.prototype.$api = axios.create({
            baseURL: 'https://web.service.carteiraholder.com.br'
        })

        Vue.prototype.$newApi = axios.create({
            baseURL: 'https://carteiraholder.com.br/api/'
        })

        // Vue.prototype.$api.interceptors.request.use(config => {
        //     (firebase.auth().currentUser != null) ? config.headers = { 'Authorization': 'Basic ' + Buffer.from(`${ md5( 'Meu@MD5#Key$1990' + config.data) }:${ config.data }`, 'utf8').toString('base64')} : null
        //     // (firebase.auth().currentUser != null) ? config.headers = { 'Authorization': 'Basic ' + Buffer.from(`${firebase.auth().currentUser.email}:${firebase.auth().currentUser.uid}`, 'utf8').toString('base64')} : null
        //     return config
        // }, error => Promise.reject(error))
    }

})