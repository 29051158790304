<template>
  <div class="page">
    <div class="page-main">
      <router-view name="Header"> </router-view>
      <router-view />
    </div>
    <router-view name="Footer"> </router-view>
  </div>
  <!-- <div>
    <img src="https://enfoquevisual.com.br/cdn/shop/products/104-022.jpg?v=1571921877" alt="" srcset="">    
  </div> -->
</template>

<script>
export default {
     
 
}
</script>


<style>
  @import "./style/dashboard.css";
  .table th, .table td {
      padding:0px;
      vertical-align: middle;
      border-top: 1px solid #dee2e6;
  }
  .table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
      background-color: #b0faf1;
  }
  .price{
      color: darkblue;
      margin-bottom: -50px;
  }
  
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .container, .container-sm, .container-md, .container-lg, .container-xl {
      max-width: 96% !important;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
