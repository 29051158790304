<template>
    <div class="page-login">
        <router-view  />
    </div>
</template>

<script>
import firebase from "firebase/app"
export default {
	mounted(){
		firebase.analytics().logEvent('View page Login');
	}
    
}
</script>

<style>
.page-login {
    height: 100vh;
	background: url('../assets/background/bg.jpg');
	background-size: cover;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
	min-height: 100%;
}

</style>