<template>
  <div v-if="ShowLoading">
    <div id="overlay" class="page-load">
      <b-container>
        <b-row>
          <b-col>
            <div class="col-login mx-auto">
              <b-card class="text-center">
                <h4>Aguarde!!!</h4>
                <p>
                  <font-awesome-icon
                    icon="spinner"
                    class="fa-spin header-loading"
                    size="4x"
                  />
                </p>
                <h6>Estamos consolidando sua carteira 😎</h6>
              </b-card>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import firebase from "firebase/app";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      count: 0,
      ShowLoading: false,
      index: 0,
      allClass: [
        { code: "acoes", isBuy: true, objectivePercentage: 0 },
        { code: "fiis", isBuy: true, objectivePercentage: 0 },
        { code: "etfs", isBuy: true, objectivePercentage: 0 },
        { code: "rf", isBuy: true, objectivePercentage: 0 },
        { code: "bdrs", isBuy: true, objectivePercentage: 0 },
        { code: "stocks", isBuy: true, objectivePercentage: 0 },
        { code: "criptomoedas", isBuy: true, objectivePercentage: 0 },
        { code: "reits", isBuy: true, objectivePercentage: 0 },
        { code: "etf_eua", isBuy: true, objectivePercentage: 0 },
        { code: "adrs", isBuy: true, objectivePercentage: 0 },
      ],
    };
  },
  created() {
    this.$store.state.user.CurrentUser = firebase.auth().currentUser;
    // this.$api.post("/Portfolio/UpdatePricesBD/f23ded3ef1655c1f04ba1f9b563a87cc")
    this.GetSnapshot();
  },
  computed: {
    lastConsolidated() {
      if (this.$store.state.portfolio.DataBase == null) return;
      return this.$store.state.portfolio.DataBase.settings.lastConsolidated;
    },
  },
  watch: {
    lastConsolidated() {
      if (this.$store.state.portfolio.DataBase == null) return;
      this.ConsolidatedPortfolio(this.$store.state.portfolio.DataBase);
    },
  },
  methods: {
    ...mapActions("global", ["GetAccessToken"]),
    GetSnapshot() {
      this.$store.state.global.firestore = firebase
        .firestore()
        .collection("account")
        .doc(this.$store.state.user.CurrentUser.uid)
        .onSnapshot(async (dataSnapshot) => {
          if (!dataSnapshot.exists) {
            Swal.fire({
              icon: "warning",
              title: "Atenção",
              text: "Não conseguimos carregar os dados da sua carteira, clique em 'Carregar novamente', caso o erro continue, tente acessar por um outro navegador ou entrar em contato com nosso suporte: contato@carteiraholder.com.br",
              showDenyButton: false,
              showCancelButton: false,
              confirmButtonText: `Carregar novamente`,
              cancelButtonText: `Cancelar`,
            }).then((result) => {
              if (result.isConfirmed) {
                this.GetSnapshot();
              }
            });
          } else {
            this.$store.state.portfolio.DataBase = await dataSnapshot.data();

            if (this.$store.state.portfolio.DataBase.new) {
              this.CreatedPortfolio();
            }

            if (
              this.$store.state.portfolio.DataBase.settings.premium
                .firstAccess === undefined ||
              this.$store.state.portfolio.DataBase.settings.premium
                .firstAccess == true
            ) {
              await this.$api
                .post(
                  "/Premium/GetFistAccess/" +
                    this.$store.state.user.CurrentUser.email
                )
                .then((reponse) => {
                  this.$store.state.portfolio.DataBase.settings.premium.firstAccess =
                    reponse.data.value.firstAccess;
                  this.$store.state.portfolio.DataBase.settings.premium.type =
                    reponse.data.value.type;
                  this.$store.state.portfolio.DataBase.settings.premium.expirationDate =
                    reponse.data.value.expirationDate;
                });
            }

            // if (
            //   this.$store.state.portfolio.DataBase.settings.migration ===
            //     undefined ||
            //   this.$store.state.portfolio.DataBase.settings.migration == false
            // ) {
            //   await this.$api
            //     .post(
            //       "/Migration/Exec/" + this.$store.state.user.CurrentUser.email
            //     )
            //     .then(() => {
            //       this.$store.state.portfolio.DataBase.settings.migration = true;
            //     });
            // }

            //Verifico se o Premium expirou
            const expirationDate = new Date(
              this.formatDate(
                this.$store.state.portfolio.DataBase.settings.premium
                  .expirationDate
              )
            );
            if (expirationDate < new Date())
              this.$store.state.portfolio.DataBase.settings.premium.type = 0;

            if (this.index == 0) {
              this.ConsolidatedPortfolio(this.$store.state.portfolio.DataBase);
            } else {
              this.index =
                this.$store.state.portfolio.DataBase.portfolio.stocks.length;
            }

            if (
              this.$store.state.portfolio.DataBase.restore === undefined ||
              this.$store.state.portfolio.DataBase.restore.length == 0
            )
              return;
            this.$store.state.portfolio.DataBase = JSON.parse(
              this.$store.state.portfolio.DataBase.restore
            );
            // console.log(JSON.parse(this.$store.state.portfolio.DataBase.restore))
          }
        });
    },
    async CreatedPortfolio() {
      const AccessToken = await this.GetAccessToken([
        this.$store.state.user.CurrentUser.email,
      ]);
      let params = new URLSearchParams();
      params.append("email", this.$store.state.user.CurrentUser.email);
      params.append("AccessToken", AccessToken);

      this.$newApi
        .post("/Portfolio/GetNewPortfolio", params)
        .then((Portfolio) => {
          if (Portfolio.data.error) {
            this.GetSnapshot();
            return;
          }
          const JsonPortfolio = Portfolio.data;
          firebase
            .firestore()
            .collection("account")
            .doc(this.$store.state.user.CurrentUser.uid)
            .set(JsonPortfolio)
            .catch((error) => {
              let params = new URLSearchParams();
              params.append("email", this.$store.state.user.CurrentUser.email);
              params.append("event", JSON.stringify(error));
              this.$api.post("/Errorlog/Set", params);
              // console.log(error);
            })
            .then(() => window.location.reload(false));
        });
    },
    async ConsolidatedPortfolioBlock(Json) {
      this.ShowLoading = true;
      let params = new URLSearchParams();
      params.append(
        "codes",
        Json.portfolio.stocks.map((el) => {
          return el.class == "criptomoedas" ? el.code + "-USD" : el.code;
        })
      );
      // params.append('portfolio', btoa(JSON.stringify(Json.portfolio.stocks)) )

      await this.$newApi
        .post("/Portfolio/Consolidated", params)
        .then((Response) => {
          for (
            this.index = 0;
            this.index < Json.portfolio.stocks.length;
            this.index++
          ) {
            const element = Json.portfolio.stocks[this.index];
            let portfolio = Response.data.find(
              (portfolio) =>
                portfolio.code ==
                (element.class == "criptomoedas"
                  ? element.code + "-USD"
                  : element.code)
            );

            // console.log(parseFloat(portfolio.price_brl));
            if (
              portfolio != undefined &&
              !isNaN(portfolio.price_brl) &&
              !isNaN(portfolio.price_usd) &&
              portfolio.price_brl != null
            ) {
              Json.portfolio.stocks[this.index].priceBRL = parseFloat(
                portfolio.price_brl
              );
              Json.portfolio.stocks[this.index].priceUSD = parseFloat(
                portfolio.price_usd
              );
            }

            // try {

            //     if (Json.portfolio.stocks[this.index].sector === undefined || Json.portfolio.stocks[this.index].sector.sector == null || Json.portfolio.stocks[this.index].sector.segment == null) {
            //         Json.portfolio.stocks[this.index].sector =  portfolio != undefined ? portfolio.sector : ''
            //         Json.portfolio.stocks[this.index].segment =  portfolio != undefined ? portfolio.segment : ''
            //     }
            // } catch (error) {

            // }

            if (element.hasOwnProperty("sector") == false)
              element.sector = { sector: "", segment: "" };
          }

          //CONTAS MUITO ANTIGAS NÂO TINHA TODAS AS CLASS AQUI AJUSTO ISSO E DEIXO TODAS COM A MESMA QUANTIDADE
          if (Json.portfolio.class.length < 10) {
            for (let index = 0; index < this.allClass.length; index++) {
              const element = this.allClass[index];
              let FilterClass = Json.portfolio.class.filter(
                (item) => item.code == element.code
              );
              if (FilterClass.length > 0) {
                element.isBuy = FilterClass[0].isBuy;
                element.objectivePercentage =
                  FilterClass[0].objectivePercentage;
              }
            }
            Json.portfolio.class = this.allClass;
          }

          Json.settings.lastConsolidated = Date.parse(
            new Date(this.formatDate(new Date()))
          );
          firebase
            .firestore()
            .collection("account")
            .doc(this.$store.state.user.CurrentUser.uid)
            .set(Json)
            .then(() => {
              this.ShowLoading = false;
            })
            .catch((error) => {
              let params = new URLSearchParams();
              params.append("email", this.$store.state.user.CurrentUser.email);
              params.append("event", JSON.stringify(error));
              this.$api.post("/Errorlog/Set", params);
              console.log(error);
            });
        })
        .finally(() => {
          this.ShowLoading = false;
        });
    },
    async ConsolidatedPortfolio(Json) {
      if (Json.settings === undefined) return;
      const DateNow = new Date(this.formatDate(new Date()));

      let lastConsolidated = new Date(Json.settings.lastConsolidated);
      lastConsolidated.setDate(lastConsolidated.getDate() + 1);

      if (Date.parse(lastConsolidated) > Date.parse(DateNow)) {
        this.ShowLoading = false;
        return;
      }
      // ENVIO BLOCOS INTEIROS
      this.ConsolidatedPortfolioBlock(Json);
    },
    async GetDolar() {
      return await this.$newApi.get("/dolar").then((Dolar) => {
        return parseFloat(Dolar.data);
      });
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
  },
};
</script>

<style>
.page-load {
  background: linear-gradient(to left, #40a9a8, #4c91ce);
  height: 100vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 111;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}
</style>
