<template>
   <div >
        <b-dropdown right size="lg" variant="link" toggle-class="text-decoration-none" no-caret>  
            <template #button-content >
                <span id="avatar" class="avatar avatar-md brround"  width="40" height="40" :style="' background-image: url(' +  $options.filters.PERFIL_IMG(currentUser.photoURL) + ')'"></span>
            </template>
            <b-dropdown-text>
                <div class="text-center">
                    <h6 v-show="currentUser.displayName != null">{{currentUser.displayName}}</h6>
                    <small>{{currentUser.email}}</small>
                </div>
            </b-dropdown-text>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item to="/profile"> <font-awesome-icon icon='user' /> Perfil</b-dropdown-item>
            <b-dropdown-item to="/settings"> <font-awesome-icon icon='wrench' /> Configurações</b-dropdown-item>
            <b-dropdown-item href="/notification"> <font-awesome-icon icon='bell' /> Notificações</b-dropdown-item>
            <b-dropdown-item href="/history"> <font-awesome-icon icon='history' /> Histórico de Pagamento</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item to="/tutorial"> <font-awesome-icon icon='graduation-cap' /> Tutorial</b-dropdown-item>
            <b-dropdown-item to="/help"> <font-awesome-icon icon='life-ring' /> Precisa de ajuda?</b-dropdown-item>
            <b-dropdown-item href="#" @click="SingOut"> <font-awesome-icon icon='sign-out-alt' /> Sair</b-dropdown-item>
        </b-dropdown>
    </div>
</template>

<script>
import firebase from "firebase/app"
export default {
    computed:{
        currentUser(){
            return this.$store.state.user.CurrentUser
        },
    },
    methods:{
        SingOut(){
            this.$store.state.global.firestore()
            firebase.auth().signOut().then(() => {
                this.$store.state.portfolio.DataBase = null;
                this.$router.replace('/');
            })
        }
    }
}
</script>

<style>

</style>