<template>
    <div>
        <Header />
    </div>
</template>

<script>
import Header from './../../components/template/Header'
export default {
    components: { Header },
    
}
</script>

<style>

</style>