<template>
  <div></div>
  <!-- <div class="card">
        <div class="card-body text-center">
            <span v-for="(book, index) in books" :key="index"  v-html="book"></span>
            
        </div>
    </div> -->
</template>

<script>
export default {
  data() {
    return {
      links: [
        '<a href="https://www.amazon.com.br/milagre-manh%C3%A3-Hal-Elrod/dp/8576849941?pd_rd_w=swfZo&pf_rd_p=8097bfa3-b5f9-418d-8cea-cd5e7772e537&pf_rd_r=7C4Y1PS76BPZ7VPT6GGB&pd_rd_r=a3358baa-3163-4781-9e17-87925bd4d12a&pd_rd_wg=bX1Xy&pd_rd_i=8576849941&psc=1&linkCode=li2&tag=carteirahol06-20&linkId=554c2c62801ce4328e5bb61933f17c9e&language=pt_BR&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=8576849941&Format=_SL160_&ID=AsinImage&MarketPlace=BR&ServiceVersion=20070822&WS=1&tag=carteirahol06-20&language=pt_BR" ></a><img src="https://ir-br.amazon-adsystem.com/e/ir?t=carteirahol06-20&language=pt_BR&l=li2&o=33&a=8576849941" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
        '<a href="https://www.amazon.com.br/poder-do-h%C3%A1bito-Charles-Duhigg/dp/8539004119?pd_rd_w=l7NyN&pf_rd_p=8097bfa3-b5f9-418d-8cea-cd5e7772e537&pf_rd_r=17CE5V2E8D0RYNRV5C9K&pd_rd_r=a2943a2c-0759-4b79-8be3-0f6680f4e117&pd_rd_wg=JIMVv&pd_rd_i=8539004119&psc=1&linkCode=li2&tag=carteirahol06-20&linkId=a4b51457412bb289c58e8a4ddef97ca4&language=pt_BR&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=8539004119&Format=_SL160_&ID=AsinImage&MarketPlace=BR&ServiceVersion=20070822&WS=1&tag=carteirahol06-20&language=pt_BR" ></a><img src="https://ir-br.amazon-adsystem.com/e/ir?t=carteirahol06-20&language=pt_BR&l=li2&o=33&a=8539004119" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
        '<a href="https://www.amazon.com.br/Homem-Mais-Rico-Babil%C3%B4nia/dp/8595081530?pd_rd_w=VBHpp&pf_rd_p=4a943320-02ab-4775-ad7a-eaf57d00a244&pf_rd_r=Y5ABK09ENV37JVKGAFA1&pd_rd_r=e562aa4f-1529-423c-a292-b1067cd8bc5d&pd_rd_wg=YDKVw&pd_rd_i=8595081530&psc=1&linkCode=li2&tag=carteirahol06-20&linkId=c3bbe2283bc5d4d1a6af3aeca7349aa5&language=pt_BR&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=8595081530&Format=_SL160_&ID=AsinImage&MarketPlace=BR&ServiceVersion=20070822&WS=1&tag=carteirahol06-20&language=pt_BR" ></a><img src="https://ir-br.amazon-adsystem.com/e/ir?t=carteirahol06-20&language=pt_BR&l=li2&o=33&a=8595081530" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
        '<a href="https://www.amazon.com.br/Mil-Milh%C3%A3o-Sem-Cortar-Cafezinho/dp/8595083274?pd_rd_w=DOjfK&pf_rd_p=8097bfa3-b5f9-418d-8cea-cd5e7772e537&pf_rd_r=69X4D1ZHF0W3R6Y9XNP1&pd_rd_r=2b06db72-3de3-4122-b856-549e36fba25e&pd_rd_wg=mRw6w&pd_rd_i=8595083274&psc=1&linkCode=li2&tag=carteirahol06-20&linkId=cac14ca2c393daefef224d0897a58c6e&language=pt_BR&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=8595083274&Format=_SL160_&ID=AsinImage&MarketPlace=BR&ServiceVersion=20070822&WS=1&tag=carteirahol06-20&language=pt_BR" ></a><img src="https://ir-br.amazon-adsystem.com/e/ir?t=carteirahol06-20&language=pt_BR&l=li2&o=33&a=8595083274" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
        '<a href="https://www.amazon.com.br/Mais-esperto-que-Diabo-liberdade/dp/8568014003?pd_rd_w=R2lP6&pf_rd_p=8097bfa3-b5f9-418d-8cea-cd5e7772e537&pf_rd_r=JBS82HGDT0EQCVKKFXWE&pd_rd_r=07fdc3ff-7688-4eb6-9c3d-afa2c1fb5789&pd_rd_wg=SyqQA&pd_rd_i=8568014003&psc=1&linkCode=li2&tag=carteirahol06-20&linkId=d7e0f9ba711d9c187b788cce3a8dee79&language=pt_BR&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=8568014003&Format=_SL160_&ID=AsinImage&MarketPlace=BR&ServiceVersion=20070822&WS=1&tag=carteirahol06-20&language=pt_BR" ></a><img src="https://ir-br.amazon-adsystem.com/e/ir?t=carteirahol06-20&language=pt_BR&l=li2&o=33&a=8568014003" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
        '<a href="https://www.amazon.com.br/segredos-mente-milion%C3%A1ria-Harv-Eker/dp/8575422391?pd_rd_w=0XBuU&pf_rd_p=8097bfa3-b5f9-418d-8cea-cd5e7772e537&pf_rd_r=SSSRBKX8RKCHJG2CPBHW&pd_rd_r=b8685098-d12d-408d-8c00-7d7e744ac4c2&pd_rd_wg=kGGmf&pd_rd_i=8575422391&psc=1&linkCode=li2&tag=carteirahol06-20&linkId=807b1da7ab278c939bb5b02df0ae94c6&language=pt_BR&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=8575422391&Format=_SL160_&ID=AsinImage&MarketPlace=BR&ServiceVersion=20070822&WS=1&tag=carteirahol06-20&language=pt_BR" ></a><img src="https://ir-br.amazon-adsystem.com/e/ir?t=carteirahol06-20&language=pt_BR&l=li2&o=33&a=8575422391" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
        '<a href="https://www.amazon.com.br/Quem-Pensa-Enriquece-Legado-Napoleon/dp/8568014542?pd_rd_w=R2lP6&pf_rd_p=8097bfa3-b5f9-418d-8cea-cd5e7772e537&pf_rd_r=JBS82HGDT0EQCVKKFXWE&pd_rd_r=07fdc3ff-7688-4eb6-9c3d-afa2c1fb5789&pd_rd_wg=SyqQA&pd_rd_i=8568014542&psc=1&linkCode=li2&tag=carteirahol06-20&linkId=1026bd204aece98d86c047c8fd333e67&language=pt_BR&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=8568014542&Format=_SL160_&ID=AsinImage&MarketPlace=BR&ServiceVersion=20070822&WS=1&tag=carteirahol06-20&language=pt_BR" ></a><img src="https://ir-br.amazon-adsystem.com/e/ir?t=carteirahol06-20&language=pt_BR&l=li2&o=33&a=8568014542" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
        '<a href="https://www.amazon.com.br/Pai-rico-pai-pobre-anos/dp/8550801488?pd_rd_w=F54go&pf_rd_p=8097bfa3-b5f9-418d-8cea-cd5e7772e537&pf_rd_r=CR61WE139NEBXKTWJBNN&pd_rd_r=39e9a844-1997-418e-b1c1-027ab2bf3c59&pd_rd_wg=suDgM&pd_rd_i=8550801488&psc=1&linkCode=li2&tag=carteirahol06-20&linkId=df5ba4bf1f12763552caaa16d0412b52&language=pt_BR&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=8550801488&Format=_SL160_&ID=AsinImage&MarketPlace=BR&ServiceVersion=20070822&WS=1&tag=carteirahol06-20&language=pt_BR" ></a><img src="https://ir-br.amazon-adsystem.com/e/ir?t=carteirahol06-20&language=pt_BR&l=li2&o=33&a=8550801488" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
        '<a href="https://www.amazon.com.br/Investidor-Inteligente-Benjamin-Graham/dp/8595080801?pd_rd_w=KI2O1&pf_rd_p=8097bfa3-b5f9-418d-8cea-cd5e7772e537&pf_rd_r=VP1MRWA68H4PJJEPHN54&pd_rd_r=c76650c5-2090-4305-a10c-d784d49fa617&pd_rd_wg=2Chy4&pd_rd_i=8595080801&psc=1&linkCode=li2&tag=carteirahol06-20&linkId=8b90e7ef43a73bae81a5d5d523d39d5d&language=pt_BR&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=8595080801&Format=_SL160_&ID=AsinImage&MarketPlace=BR&ServiceVersion=20070822&WS=1&tag=carteirahol06-20&language=pt_BR" ></a><img src="https://ir-br.amazon-adsystem.com/e/ir?t=carteirahol06-20&language=pt_BR&l=li2&o=33&a=8595080801" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
        '<a href="https://www.amazon.com.br/Investidor-Inteligente-Edi-C3-A7-C3-A3o-Exclusiva-Amazon-dp-8595085234/dp/8595085234?&linkCode=li2&tag=carteirahol06-20&linkId=a779a1cc057d22f9d5da6420298f0fc0&language=pt_BR&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=8595085234&Format=_SL160_&ID=AsinImage&MarketPlace=BR&ServiceVersion=20070822&WS=1&tag=carteirahol06-20&language=pt_BR" ></a><img src="https://ir-br.amazon-adsystem.com/e/ir?t=carteirahol06-20&language=pt_BR&l=li2&o=33&a=8595085234" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
        '<a href="https://www.amazon.com.br/jeito-Warren-Buffett-investir-investidor/dp/8557173067?pd_rd_w=VHh2a&pf_rd_p=4a943320-02ab-4775-ad7a-eaf57d00a244&pf_rd_r=8BR83XM58PDS32N8B0JT&pd_rd_r=6bf0d3fd-8f81-4098-af56-b681a0623ad1&pd_rd_wg=9KRVN&pd_rd_i=8557173067&psc=1&linkCode=li2&tag=carteirahol06-20&linkId=59e4358d83a2febd1d57e5e3b651e9cd&language=pt_BR&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=8557173067&Format=_SL160_&ID=AsinImage&MarketPlace=BR&ServiceVersion=20070822&WS=1&tag=carteirahol06-20&language=pt_BR" ></a><img src="https://ir-br.amazon-adsystem.com/e/ir?t=carteirahol06-20&language=pt_BR&l=li2&o=33&a=8557173067" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
        '<a href="https://www.amazon.com.br/Mais-Importante-para-Investidor-Financeiro/dp/6556600202?pd_rd_w=W50m9&pf_rd_p=8097bfa3-b5f9-418d-8cea-cd5e7772e537&pf_rd_r=EJFKX756V6W2VWW30HP3&pd_rd_r=723a6b1c-f817-49d3-a82d-21987a844d78&pd_rd_wg=VoZuq&pd_rd_i=6556600202&psc=1&linkCode=li2&tag=carteirahol06-20&linkId=da54e4bf59bf1e74c5011420475f17ea&language=pt_BR&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=6556600202&Format=_SL160_&ID=AsinImage&MarketPlace=BR&ServiceVersion=20070822&WS=1&tag=carteirahol06-20&language=pt_BR" ></a><img src="https://ir-br.amazon-adsystem.com/e/ir?t=carteirahol06-20&language=pt_BR&l=li2&o=33&a=6556600202" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
        '<a href="https://www.amazon.com.br/Dominando-Ciclo-Mercado-Reconhecer-Seguran%C3%A7a/dp/8550808784?pd_rd_w=hVHd6&pf_rd_p=8097bfa3-b5f9-418d-8cea-cd5e7772e537&pf_rd_r=0CCM7QRWTJ2JJDSGVEP5&pd_rd_r=defa8763-683b-47b1-8d7a-d97b6244a76a&pd_rd_wg=3Ywj4&pd_rd_i=8550808784&psc=1&linkCode=li2&tag=carteirahol06-20&linkId=c8239d92222a54ec507861673b594b9c&language=pt_BR&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=8550808784&Format=_SL160_&ID=AsinImage&MarketPlace=BR&ServiceVersion=20070822&WS=1&tag=carteirahol06-20&language=pt_BR" ></a><img src="https://ir-br.amazon-adsystem.com/e/ir?t=carteirahol06-20&language=pt_BR&l=li2&o=33&a=8550808784" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
        '<a href="https://www.amazon.com.br/Batendo-Mercado-Hist%C3%B3rias-estrat%C3%A9gias-vencedoras/dp/6556600474?pd_rd_w=pTNIs&pf_rd_p=8097bfa3-b5f9-418d-8cea-cd5e7772e537&pf_rd_r=KEY385R44D042JDHZAV8&pd_rd_r=ca445010-b68b-4ae3-8d69-1f74a35e6230&pd_rd_wg=xyQ6h&pd_rd_i=6556600474&psc=1&linkCode=li2&tag=carteirahol06-20&linkId=ebb6f0685dce0bc60f7ed00d018cb423&language=pt_BR&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=6556600474&Format=_SL160_&ID=AsinImage&MarketPlace=BR&ServiceVersion=20070822&WS=1&tag=carteirahol06-20&language=pt_BR" ></a><img src="https://ir-br.amazon-adsystem.com/e/ir?t=carteirahol06-20&language=pt_BR&l=li2&o=33&a=6556600474" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
        '<a href="https://www.amazon.com.br/Princ%C3%ADpios-Ray-Dalio/dp/8551003429?pd_rd_w=hVHd6&pf_rd_p=8097bfa3-b5f9-418d-8cea-cd5e7772e537&pf_rd_r=0CCM7QRWTJ2JJDSGVEP5&pd_rd_r=defa8763-683b-47b1-8d7a-d97b6244a76a&pd_rd_wg=3Ywj4&pd_rd_i=8551003429&psc=1&linkCode=li2&tag=carteirahol06-20&linkId=87ee5cc648acfc66576a81c7db2c970e&language=pt_BR&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=8551003429&Format=_SL160_&ID=AsinImage&MarketPlace=BR&ServiceVersion=20070822&WS=1&tag=carteirahol06-20&language=pt_BR" ></a><img src="https://ir-br.amazon-adsystem.com/e/ir?t=carteirahol06-20&language=pt_BR&l=li2&o=33&a=8551003429" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
        '<a href="https://www.amazon.com.br/Fa%C3%A7a-Fortuna-A%C3%A7%C3%B5es-Antes-Tarde/dp/8585454164?__mk_pt_BR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=MAPCCJTYCPJS&keywords=fa%C3%A7a+fortuna+com+a%C3%A7%C3%B5es&qid=1638961247&s=books&sprefix=fa%C3%A7a+for%2Cstripbooks%2C292&sr=1-2&linkCode=li2&tag=carteirahol06-20&linkId=410d0f3fc4ddf7a24d464f7f6c0895d6&language=pt_BR&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=8585454164&Format=_SL160_&ID=AsinImage&MarketPlace=BR&ServiceVersion=20070822&WS=1&tag=carteirahol06-20&language=pt_BR" ></a><img src="https://ir-br.amazon-adsystem.com/e/ir?t=carteirahol06-20&language=pt_BR&l=li2&o=33&a=8585454164" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
        '<a href="https://www.amazon.com.br/Valuation-Precificar-A%C3%A7%C3%B5es-Alexandre-POVOA/dp/8595150257?__mk_pt_BR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=3CN27EOLKUOHD&keywords=valuation&qid=1638961292&s=books&sprefix=valu%2Cstripbooks%2C403&sr=1-4&linkCode=li2&tag=carteirahol06-20&linkId=c9d86d2c702c384fddc0e3f93ecb8a00&language=pt_BR&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=8595150257&Format=_SL160_&ID=AsinImage&MarketPlace=BR&ServiceVersion=20070822&WS=1&tag=carteirahol06-20&language=pt_BR" ></a><img src="https://ir-br.amazon-adsystem.com/e/ir?t=carteirahol06-20&language=pt_BR&l=li2&o=33&a=8595150257" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
        '<a href="https://www.amazon.com.br/Avaliando-Empresas-Investindo-em-A%C3%A7%C3%B5es/dp/8575221795?pd_rd_w=aSFqV&pf_rd_p=8097bfa3-b5f9-418d-8cea-cd5e7772e537&pf_rd_r=NXTFEZ300XJQHYK02AM5&pd_rd_r=4222f082-3aec-4b97-a140-62d194138793&pd_rd_wg=kGSlF&pd_rd_i=8575221795&psc=1&linkCode=li2&tag=carteirahol06-20&linkId=3e9f67223ca9636569979748898b3756&language=pt_BR&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=8575221795&Format=_SL160_&ID=AsinImage&MarketPlace=BR&ServiceVersion=20070822&WS=1&tag=carteirahol06-20&language=pt_BR" ></a><img src="https://ir-br.amazon-adsystem.com/e/ir?t=carteirahol06-20&language=pt_BR&l=li2&o=33&a=8575221795" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
        '<a href="https://www.amazon.com.br/Investimento-Imobili%C3%A1rio-Aspectos-Princ%C3%ADpios-An%C3%A1lise/dp/8575226754?pd_rd_w=EJe9t&pf_rd_p=8097bfa3-b5f9-418d-8cea-cd5e7772e537&pf_rd_r=BA240S5DK75YTN3PPE5M&pd_rd_r=f456cdb5-ee65-4a60-9176-6c5579c1bc6e&pd_rd_wg=Im8Aq&pd_rd_i=8575226754&psc=1&linkCode=li2&tag=carteirahol06-20&linkId=cc68b9d0a468544df6a98df04405e03b&language=pt_BR&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=8575226754&Format=_SL160_&ID=AsinImage&MarketPlace=BR&ServiceVersion=20070822&WS=1&tag=carteirahol06-20&language=pt_BR" ></a><img src="https://ir-br.amazon-adsystem.com/e/ir?t=carteirahol06-20&language=pt_BR&l=li2&o=33&a=8575226754" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
        '<a href="https://www.amazon.com.br/Warren-Buffett-an%C3%A1lise-balan%C3%A7os-Exclusiva/dp/6555640758?pd_rd_w=9FF7v&pf_rd_p=8097bfa3-b5f9-418d-8cea-cd5e7772e537&pf_rd_r=31JPF85AST1TWE0DJ6G7&pd_rd_r=0e871802-a415-443e-b12d-e2f7fc522f4c&pd_rd_wg=jUKtB&pd_rd_i=6555640758&psc=1&linkCode=li2&tag=carteirahol06-20&linkId=423aadd1dfc47671a759d2db4ac465fb&language=pt_BR&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=6555640758&Format=_SL160_&ID=AsinImage&MarketPlace=BR&ServiceVersion=20070822&WS=1&tag=carteirahol06-20&language=pt_BR" ></a><img src="https://ir-br.amazon-adsystem.com/e/ir?t=carteirahol06-20&language=pt_BR&l=li2&o=33&a=6555640758" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
        '<a href="https://www.amazon.com.br/Investimento-valor-Graham-Buffett-al%C3%A9m/dp/8582605668?pd_rd_w=EELZa&pf_rd_p=8097bfa3-b5f9-418d-8cea-cd5e7772e537&pf_rd_r=FJYHEJ35J33GTA0QW54V&pd_rd_r=1068aee1-c1ba-4e62-94a9-50c9f7141f61&pd_rd_wg=tkGJn&pd_rd_i=8582605668&psc=1&linkCode=li2&tag=carteirahol06-20&linkId=e73adb91354c6dcf8d0c2d52278380d6&language=pt_BR&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=8582605668&Format=_SL160_&ID=AsinImage&MarketPlace=BR&ServiceVersion=20070822&WS=1&tag=carteirahol06-20&language=pt_BR" ></a><img src="https://ir-br.amazon-adsystem.com/e/ir?t=carteirahol06-20&language=pt_BR&l=li2&o=33&a=8582605668" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
        '<a href="https://www.amazon.com.br/Ricos-s%C3%A1bios-felizes-investidores-bem-sucedido/dp/6555641916?pd_rd_w=djeh6&pf_rd_p=8097bfa3-b5f9-418d-8cea-cd5e7772e537&pf_rd_r=464KFSG7M70JMRESJ5WC&pd_rd_r=3482d80f-f100-4b78-80d4-eae0102a48fa&pd_rd_wg=cLlsy&pd_rd_i=6555641916&psc=1&linkCode=li2&tag=carteirahol06-20&linkId=e2bfd01393dd8e3d4f331f66b8c85308&language=pt_BR&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=6555641916&Format=_SL160_&ID=AsinImage&MarketPlace=BR&ServiceVersion=20070822&WS=1&tag=carteirahol06-20&language=pt_BR" ></a><img src="https://ir-br.amazon-adsystem.com/e/ir?t=carteirahol06-20&language=pt_BR&l=li2&o=33&a=6555641916" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
        '<a href="https://www.amazon.com.br/psicologia-financeira-atemporais-gan%C3%A2ncia-felicidade/dp/6555111100?pd_rd_w=6FlTv&pf_rd_p=8097bfa3-b5f9-418d-8cea-cd5e7772e537&pf_rd_r=TV7M0V8MNR0PCRFB1PCD&pd_rd_r=1ca83b31-f645-4586-8a20-ed6bf3b3271f&pd_rd_wg=xFDUD&pd_rd_i=6555111100&psc=1&linkCode=li2&tag=carteirahol06-20&linkId=1eeb925e685be986280813a691d38a48&language=pt_BR&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=6555111100&Format=_SL160_&ID=AsinImage&MarketPlace=BR&ServiceVersion=20070822&WS=1&tag=carteirahol06-20&language=pt_BR" ></a><img src="https://ir-br.amazon-adsystem.com/e/ir?t=carteirahol06-20&language=pt_BR&l=li2&o=33&a=6555111100" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
        '<a href="https://www.amazon.com.br/Tempo-Melhor-Neg%C3%B3cio-Automatizado-Liberdade/dp/6555205172?pd_rd_w=6FlTv&pf_rd_p=8097bfa3-b5f9-418d-8cea-cd5e7772e537&pf_rd_r=TV7M0V8MNR0PCRFB1PCD&pd_rd_r=1ca83b31-f645-4586-8a20-ed6bf3b3271f&pd_rd_wg=xFDUD&pd_rd_i=6555205172&psc=1&linkCode=li2&tag=carteirahol06-20&linkId=9a082a434b9776586bb0103e803b9d97&language=pt_BR&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=6555205172&Format=_SL160_&ID=AsinImage&MarketPlace=BR&ServiceVersion=20070822&WS=1&tag=carteirahol06-20&language=pt_BR" ></a><img src="https://ir-br.amazon-adsystem.com/e/ir?t=carteirahol06-20&language=pt_BR&l=li2&o=33&a=6555205172" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
        '<a href="https://www.amazon.com.br/A%C3%A7%C3%B5es-Comuns-Lucros-Extraordin%C3%A1rios-Edi%C3%A7%C3%A3o/dp/6558100126?pd_rd_w=EuUwr&pf_rd_p=8097bfa3-b5f9-418d-8cea-cd5e7772e537&pf_rd_r=9DF8SKBFXHW7F2ZWF6VX&pd_rd_r=da5c323f-9dbd-4283-8064-a389df4478a9&pd_rd_wg=L45gq&pd_rd_i=6558100126&psc=1&linkCode=li2&tag=carteirahol06-20&linkId=20be03c016ca4187dcfe622e96b10891&language=pt_BR&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=6558100126&Format=_SL160_&ID=AsinImage&MarketPlace=BR&ServiceVersion=20070822&WS=1&tag=carteirahol06-20&language=pt_BR" ></a><img src="https://ir-br.amazon-adsystem.com/e/ir?t=carteirahol06-20&language=pt_BR&l=li2&o=33&a=6558100126" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
        '<a href="https://www.amazon.com.br/SMART-MONEY-investidores-dinheiro-inteligente/dp/8545202822?__mk_pt_BR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=3CTF7SHEJLWX8&keywords=smart+money&qid=1638967921&s=books&sprefix=smart%2Cstripbooks%2C437&sr=1-2&linkCode=li2&tag=carteirahol06-20&linkId=191e32bc64612361a6a2af0a41534071&language=pt_BR&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=8545202822&Format=_SL160_&ID=AsinImage&MarketPlace=BR&ServiceVersion=20070822&WS=1&tag=carteirahol06-20&language=pt_BR" ></a><img src="https://ir-br.amazon-adsystem.com/e/ir?t=carteirahol06-20&language=pt_BR&l=li2&o=33&a=8545202822" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
        '<a href="https://www.amazon.com.br/Mercado-Financeiro-Produtos-Servi%C3%A7os-Edi%C3%A7%C3%A3o/dp/8541403645?__mk_pt_BR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=11IRA79Z1AW5Z&keywords=mercado+financeiro&qid=1638967811&s=books&sprefix=mercado%2Cstripbooks%2C284&sr=1-3&ufe=app_do%3Aamzn1.fos.25548f35-0de7-44b3-b28e-0f56f3f96147&linkCode=li2&tag=carteirahol06-20&linkId=a0fb5115d41febb1bba8e830c73c08c3&language=pt_BR&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=8541403645&Format=_SL160_&ID=AsinImage&MarketPlace=BR&ServiceVersion=20070822&WS=1&tag=carteirahol06-20&language=pt_BR" ></a><img src="https://ir-br.amazon-adsystem.com/e/ir?t=carteirahol06-20&language=pt_BR&l=li2&o=33&a=8541403645" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
        '<a href="https://www.amazon.com.br/Mercado-Financeiro-Alexandre-Assaf-Neto/dp/8597017791?__mk_pt_BR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=11IRA79Z1AW5Z&keywords=mercado+financeiro&qid=1638967811&s=books&sprefix=mercado%2Cstripbooks%2C284&sr=1-4&ufe=app_do%3Aamzn1.fos.6a09f7ec-d911-4889-ad70-de8dd83c8a74&linkCode=li2&tag=carteirahol06-20&linkId=c3e21edbca5fb001ece2e93ae6f0fb31&language=pt_BR&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=8597017791&Format=_SL160_&ID=AsinImage&MarketPlace=BR&ServiceVersion=20070822&WS=1&tag=carteirahol06-20&language=pt_BR" ></a><img src="https://ir-br.amazon-adsystem.com/e/ir?t=carteirahol06-20&language=pt_BR&l=li2&o=33&a=8597017791" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
        '<a href="https://www.amazon.com.br/Mercado-Capitais-Juliano-Pinheiro/dp/8597021438?__mk_pt_BR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=11IRA79Z1AW5Z&keywords=mercado+financeiro&qid=1638967811&s=books&sprefix=mercado%2Cstripbooks%2C284&sr=1-8&ufe=app_do%3Aamzn1.fos.fcd6d665-32ba-4479-9f21-b774e276a678&linkCode=li2&tag=carteirahol06-20&linkId=9b0284e71eccede7b3fe879fbb24a667&language=pt_BR&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=8597021438&Format=_SL160_&ID=AsinImage&MarketPlace=BR&ServiceVersion=20070822&WS=1&tag=carteirahol06-20&language=pt_BR" ></a><img src="https://ir-br.amazon-adsystem.com/e/ir?t=carteirahol06-20&language=pt_BR&l=li2&o=33&a=8597021438" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
        '<a href="https://www.amazon.com.br/M%C3%A9todo-Financeiro-Do-Primo-Rico/dp/8595087059?__mk_pt_BR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&keywords=primo+rico&qid=1638967859&s=books&sr=1-3&linkCode=li2&tag=carteirahol06-20&linkId=10f6b39f71db2a20575baeea07057717&language=pt_BR&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=8595087059&Format=_SL160_&ID=AsinImage&MarketPlace=BR&ServiceVersion=20070822&WS=1&tag=carteirahol06-20&language=pt_BR" ></a><img src="https://ir-br.amazon-adsystem.com/e/ir?t=carteirahol06-20&language=pt_BR&l=li2&o=33&a=8595087059" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
        '<a href="https://www.amazon.com.br/Trabalhe-4-Horas-por-Semana/dp/8542211561?__mk_pt_BR=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=F64I0MASOBPE&keywords=trabalhe+4+horas+por+semana&qid=1638967885&s=books&sprefix=traba%2Cstripbooks%2C290&sr=1-3&linkCode=li2&tag=carteirahol06-20&linkId=c52700c9f33dbc6facb268abbdd21028&language=pt_BR&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=8542211561&Format=_SL160_&ID=AsinImage&MarketPlace=BR&ServiceVersion=20070822&WS=1&tag=carteirahol06-20&language=pt_BR" ></a><img src="https://ir-br.amazon-adsystem.com/e/ir?t=carteirahol06-20&language=pt_BR&l=li2&o=33&a=8542211561" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
      ],
      books: [],
      arrayRandom: [],
    };
  },
  computed: {
    log() {
      return this.$store.state.portfolio.DataBase.log;
    },
  },
  mounted() {
    let widthSize = this.screenSize();
    let divider = widthSize < 400 ? 225 : 155;
    let SumFor = widthSize / divider;

    this.books = [];
    this.arrayRandom = [];

    for (let index = 0; index < SumFor; index++) {
      let indexLinks = this.indexRandom();

      this.books.push(this.links[indexLinks]);
    }
    //console.log(this.books);

    /*
        var splits = this.links[index].toString().split(" src=")[1];
        splits = splits.toString().split("pt_BR")[0] + "pt_BR";
        splits = "http://" + splits.slice(3, splits.length)
        console.log(splits);
        */
  },
  methods: {
    indexRandom() {
      let max = this.links.length - 1;
      let min = 0;
      let number = Math.floor(Math.random() * (max - min) + min);

      if (this.arrayRandom.find((element) => element == number))
        return this.indexRandom();

      this.arrayRandom.push(number);
      return number;
    },
    screenSize(widthSize = true) {
      var win = window,
        doc = document,
        docElem = doc.documentElement,
        body = doc.getElementsByTagName("body")[0],
        x = win.innerWidth || docElem.clientWidth || body.clientWidth,
        y = win.innerHeight || docElem.clientHeight || body.clientHeight;
      return widthSize ? x : y;

      //alert(x + ' × ' + y);
    },
  },
};
</script>

<style></style>
