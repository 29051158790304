import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from "firebase/app"
// import store from '@/store'

import Login from '../views/Login.vue'
import Header from '../views/template/Header.vue'
import Footer from '../views/template/Footer.vue'

Vue.use(VueRouter)

const Dashbord = () => import(/* webpackChunkName: "Dashbord" */ '../views/Dashbord.vue')
const Tutorial = () => import(/* webpackChunkName: "Dashbord" */ '../views/Tutorial.vue')
const Help = () => import(/* webpackChunkName: "Dashbord" */ '../views/Help.vue')
const Notification = () => import(/* webpackChunkName: "Dashbord" */ '../views/Notification.vue')
const Sector = () => import(/* webpackChunkName: "Dashbord" */ '../views/Sector.vue')
const Allocation = () => import(/* webpackChunkName: "Dashbord" */ '../views/Allocation.vue')


const Rebalancing = () => import(/* webpackChunkName: "Rebalancing" */ '../views/Rebalancing.vue')
const Target = () => import(/* webpackChunkName: "Rebalancing" */ '../views/Target.vue')

const Profile = () => import(/* webpackChunkName: "User" */ '../views/user/Profile.vue')
const Settings = () => import(/* webpackChunkName: "User" */ '../views/user/Settings.vue')
const History = () => import(/* webpackChunkName: "User" */ '../views/History.vue')
const Pricing = () => import(/* webpackChunkName: "User" */ '../views/Pricing.vue')

const Auth = () => import(/* webpackChunkName: "Login" */ '../components/login/Auth.vue')
const SignUp = () => import(/* webpackChunkName: "Login" */ '../components/login/SignUp.vue')
const Forgot = () => import(/* webpackChunkName: "Login" */ '../components/login/Forgot.vue')
const Action = () => import(/* webpackChunkName: "Login" */ '../components/login/Action.vue')



const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    children: [
      {
        path: '', component: Auth,
        meta: { login: true, title: 'Acessar' },
      },
      {
        path: 'SignUp', component: SignUp,
        meta: { login: true, title: 'Cadastrar'  },
      },
      {
        path: 'Forgot', component: Forgot,
        meta: { login: true, title: 'Recuperar Senha'  },
      },
      {
        path: 'Action', component: Action,
        meta: { login: true, title: 'Trocar Senha'  },
      }
    ]
  },
  {
    path: '/home',
    name: 'Home',
    components: { default: Dashbord, Header, Footer },
    props: true,
    meta: { requeresAuth: true, title: 'Dashboard' },
    children: []
  },
  {
    path: '/rebalancing',
    name: 'Rebalancing',
    components: { default: Rebalancing, Header, Footer },
    props: true,
    meta: { requeresAuth: true, title: 'Rebalancear'  },
    children: []
  },
  {
    path: '/target',
    name: 'Carteira Meta',
    components: { default: Target, Header, Footer },
    props: true,
    meta: { requeresAuth: true, title: 'Carteira Meta'  },
    children: []
  },
  {
    path: '/sector',
    name: 'Setores | Segmento',
    components: { default: Sector, Header, Footer },
    props: true,
    meta: { requeresAuth: true, title: 'Setores | Segmento'  },
    children: []
  },
  {
    path: '/allocation',
    name: 'Alocação da Carteira',
    components: { default: Allocation, Header, Footer },
    props: true,
    meta: { requeresAuth: true, title: 'Alocação da Carteira'  },
    children: []
  },
  {
    path: '/profile',
    name: 'Profile',
    components: { default: Profile, Header, Footer },
    props: true,
    meta: { requeresAuth: true, title: 'Perfil' },
    children: []
  },
  {
    path: '/tutorial',
    name: 'Tutorial',
    components: { default: Tutorial, Header, Footer },
    props: true,
    meta: { requeresAuth: true, title: 'Tutorial' },
    children: []
  },
  {
    path: '/help',
    name: 'Help',
    components: { default: Help, Header, Footer },
    props: true,
    meta: { requeresAuth: true, title: 'Ajuda' },
    children: []
  },
  {
    path: '/pricing',
    name: 'Pricing',
    components: { default: Pricing, Header, Footer },
    props: true,
    meta: { requeresAuth: true, title: 'Premium' },
    children: []
  },
  {
    path: '/notification',
    name: 'Notification',
    components: { default: Notification, Header, Footer },
    props: true,
    meta: { requeresAuth: true, title: 'Notificações' },
    children: []
  },
  {
    path: '/settings',
    name: 'Settings',
    components: { default: Settings, Header, Footer },
    props: true,
    meta: { requeresAuth: true, title: 'Configurações' },
    children: []
  },
  {
    path: '/history',
    name: 'History',
    components: { default: History, Header, Footer },
    props: true,
    meta: { requeresAuth: true, title: 'Histórico de pagamento' },
    children: []
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // console.log(store.state.portfolio.DataBase)
  // if(store.state.portfolio.DataBase != null && store.state.portfolio.DataBase.settings.premium.type != 1 && store.state.portfolio.DataBase.settings.premium.type != 2){
  //   import("@/plugins/google-adsense")
  // }
  
  const pageLogin = to.matched.some(record => record.meta.login)
  if (pageLogin) next()
  
  if(document.getElementById('headerMenuCollapse') && document.getElementById('headerMenuCollapse').classList.value == 'Klast-navbar collapse show' )   document.getElementById('MenuMobile').click()  //AJUSTAR A PORRA DO MENU NO MOBILE
  
  const currentUser = firebase.auth().currentUser
  const requeresAuth = to.matched.some(record => record.meta.requeresAuth)
  if (requeresAuth && !currentUser) next('/')
  else if (!requeresAuth && currentUser) next('home')
  else next()
})

const DEFAULT_TITLE = 'Carteira Holder';
router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = (DEFAULT_TITLE + " - " + to.meta.title) || DEFAULT_TITLE;
    });
});

export default router
