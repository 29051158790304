<template>
   <div v-if="this.DataBase != null" :class="'dropdown d-md-flex ' + ($isMobile()?'mt-2':'mt-1')"   >
        <router-link to="/notification" class="nav-link " data-toggle="dropdown">
            <font-awesome-icon icon="bell" size="2x" class="text-white"  />
            <span v-if="DataBase.notification !== undefined && DataBase.notification.filter(item => (item.view == false && item.delete == false )).length > 0" :class="'nav-unread badge badge-pill badge-' + color + ' mt-2 mr-2'">
                <b>{{BellValue}}</b>
            </span>
        </router-link>
    </div>
</template>

<script>
export default {
    data() {
        return {
            color: 'primary',
        }
    },
    computed: {
        DataBase(){
            return this.$store.state.portfolio.DataBase
        },
        BellValue(){
            return this.BellValueLength()
        }
    },
    mounted(){
        setInterval(() => {
            if(this.color == 'primary'){
                this.color = 'info'
            }else{
                this.color = 'primary'
            }
        }, 700);
    },
    methods:{
        BellValueLength(){
            if(this.DataBase.notification === undefined) return 0
            return (this.DataBase.notification.filter(item => item.view == false).length > 99)? this.DataBase.notification.filter(item => (item.view == false && item.delete == false )).length : this.DataBase.notification.filter(item => (item.view == false && item.delete == false )).length
        }
    }

}
</script>

<style scoped>
</style>