<template></template>

<script>
export default {
  computed: {
    DataBase() {
      return this.$store.state.portfolio.DataBase;
    },
  },
  mounted() {
    let Interval = 60000 * 20; //Timer de 20 min
    setTimeout(() => {
      if (this.DataBase != null) this.Backup();
    }, 1000);
    setInterval(() => {
      if (this.DataBase != null) this.Backup();
    }, Interval);
  },
  methods: {
    Backup() {
      if (this.DataBase.newPortfolio == true) return;

      this.DataBase.BackupDate = new Date().toLocaleDateString("pt-BR");

      let params = new URLSearchParams();
      params.append("uid", this.$store.state.user.CurrentUser.uid);
      params.append("email", this.$store.state.user.CurrentUser.email);
      params.append("database", btoa(JSON.stringify(this.DataBase)));

      this.$api.post("/Backup/Set", params);
    },
  },
};
</script>

<style></style>
