<template>
    <div class="page-main">
        <div class="header">
            <div class="container">
                <div class="d-flex">
                    <Logo />
                    <div class="d-flex order-lg-2 ml-auto">
                        <div v-if="DataBase != null && DataBase.settings.premium.type > 1 && !$isMobile()">
                            <span class="tag mt-3">
                                <router-link v-b-tooltip.hover title="Carteira selecionada" to="/settings" style="text-decoration:none;" class="text-primary">
                                    <font-awesome-icon icon="wallet" class="m3-2 text-warning" /> {{DataBase.portfolios.filter(item => item.select == true)[0].name.toUpperCase()}}
                                </router-link>
                            </span>
                        </div>
                        <Bell />
                        <DropUser />
                        <a class="header-toggler d-lg-none ml-3 ml-lg-0" v-b-toggle href="#headerMenuCollapse" @click.prevent id="MenuMobile" >
                            <font-awesome-icon icon='bars' size="2x"  />
                        </a>
                    </div>
                </div>
            </div>      
        </div>
        <b-collapse class="Klast-navbar" id="headerMenuCollapse">
            <NavBar />
        </b-collapse >
        <div v-if="!$isMobile()" class="Klast-navbar" id="">
            <NavBar />
        </div >   
        <Loading />
        <Premium />
        <Notification />
        <LogAccess />
        <BackUp />
    </div>
        
</template>

<script>
import DropUser from "./Header/DropUser"
import Logo from "./Header/Logo"
import NavBar from "./Header/NavBar"
import Loading from "./Header/Loading"
import Bell from "./Header/Bell"
import Premium from "./Header/Premium"
import Notification from "./Header/Notification"
import LogAccess from "./Header/LogAccess"
import BackUp from "./Header/BackUp"
export default {
    components:{ DropUser, Logo, NavBar, Loading, Bell, Premium, Notification,LogAccess,BackUp },
    mounted(){
        if (window.location.href.match(/localhost.*/)) {
            //COMO ESSA FUNC SÓ FUNFA LOCAL
            //SEMPRE QUE ESTIVER TESTANDO CHAMO ELA PARA ATUALIZAR
            //TODOS OS SETORES DOS ATIVOS QUE ESTÃO null
            //this.$api.post("/Sector/GetAll")
        }
    },
    computed:{
        DataBase:{
            get: function() {return this.$store.state.portfolio.DataBase },
            set: function(newValue) {this.$store.state.portfolio.DataBase = newValue} 
        },
    },
    
}
</script>

<style>
    .header {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
    .header-toggler{
        margin-top: 15px !important;
    }
    .header-loading{
        margin-top: 12px;
    }
</style>