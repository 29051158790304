import md5 from 'js-md5'
import Swal from 'sweetalert2'
export default {
    namespaced:true,
    state: {
        AccessToken: 'Meu@MD5#Key$1990',
        AllClassPortfolio:[
            { className:"acoes"},
            { className:"fiis"},
            { className:"rf"},
            { className:"etfs"},
            { className:"bdrs"},
            { className:"stocks"},
            { className:"reits"},
            { className:"etf_eua"},
            { className:"adrs"},
            { className:"criptomoedas"},
        ],
        code:"",
        note: 0.00,
        topPrice: 0.00,
        quantity: 0,
        buy:true,
        firestore:null
    },
    getters: {
    },
    mutations: {
    },
    actions: {
        TranslateErrorMessageFirebase(state, error){
            let ErrorMessage = "Erro ao tentar realizar esse processo, tente novamente mais tarde."
            let Type = "error"
            let Title = "Atenção!"
            switch(error.code){
                case 'auth/invalid-email':
                    ErrorMessage = 'Por favor, informe um endereço de e-mail válido.'
                    Type = "warning"
                    break
                case 'auth/email-already-in-use':
                    ErrorMessage = 'E-mail já cadastrado.'
                    Type = "warning"
                    Title= "OPS..."
                    break
                case 'auth/weak-password':
                    ErrorMessage = 'Sua senha deve ter pelo menos seis caracteres.'
                    Type = "warning"
                    break
                case 'auth/wrong-password':
                    ErrorMessage = 'Usuário e/ou senha inválidos.'
                    Type = "warning"
                    break
                case 'auth/user-not-found':
                    ErrorMessage = 'Usuário e/ou senha inválidos.'
                    break
            }
            return {
                ErrorMessage,
                Type,
                Title
            }
        },
        GetAccessToken(state, Values){
            let string = ''
            for (let index = 0; index < Values.length; index++) {
                const element = Values[index];
                string += element
                if(index != Values.length - 1) string += "|||"
            }
            return md5(this.state.global.AccessToken + string )
        },
        MsgNotPermission(state, MyHTML){
            Swal.fire({
                title: 'Opsss...',
                html:MyHTML,
                icon: 'warning',
                confirmButtonText: 'Ok',
            })
        }
    },
    modules: {
    }
}